import { Box, Button, Grid, ThemeProvider, createTheme } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PURPLE, SPRING_GREEN } from '../../colors/colors'
import { useToken } from '../../hooks/useToken'
import { Header } from './components/Header/Header'
import { KPICard } from './components/KPICard/KPICard'
import { IncomeHalfDoughnutChart } from './echarts/charts/IncomeHalfDoughnutChart'
import { NetFlowSeriesChart } from './echarts/charts/NetFlowSeriesChart'
import { CapitalSeriesChart } from './echarts/charts/CapitalSeriesChart'
import { ActiveCustomersSeriesChart } from './echarts/charts/ActiveCustomersSeriesChart'
import { BankInfo } from './echarts/charts/BankInfo'
import { useState } from 'react'

const queryClient = new QueryClient()

const dashboardStyles = {
    backgroundColor: PURPLE[300],
}

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
})

const buttonStyles = {
    fontFamily: 'Montserrat',
    fontSize: '1.4rem',
    fontWeight: '600',
    height: '4rem',
}

export type Period = '1W' | '1M' | 'YTD'

export const KPIDashboard = () => {
    const { token, decodedJwt, error, requestId, requestIdSeconds } = useToken()
    const [period, setPeriod] = useState<Period>('YTD')

    const primaryColor = PURPLE[800]
    const selectedColor = SPRING_GREEN[500]

    if (requestId) {
        if (requestIdSeconds > 0) {
            return <div className="page"><p>Authentication passphrase <b>'{requestId}'</b> is valid for another {Math.floor(requestIdSeconds / 60)} minutes and {requestIdSeconds % 60} seconds.</p></div>
        } else {
            return <div className="page"><p>Authentication passphrase <b>'{requestId}'</b> has timed out. Refresh page to try again. </p></div>
        }
    }

    if (error) return <div className="page"><p>Authentication error.</p></div>

    if (!decodedJwt) return null

    console.log('requestId', requestId)
    console.log('requestIdSeconds', requestIdSeconds)
    //if (requestId && requestIdSeconds > 0) return <div>{requestId}</div>

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <Box style={{ height: '100%' }} sx={{ backgroundColor: PURPLE[300] }}>
                    <Grid container spacing={3} style={dashboardStyles} sx={{ p: '0rem 3rem' }}>
                        <Grid item xs={5}>
                            <Header />
                        </Grid>
                        <Grid item xs={7} container sx={{ pb: '1.5rem' }} justifyContent='flex-end' alignItems='flex-end'>
                            <Button
                                variant='contained'
                                onClick={() => setPeriod('1W')}
                                style={buttonStyles}
                                sx={{ mr: 1.5, color: period === '1W' ? primaryColor : selectedColor, backgroundColor: period === '1W' ? selectedColor : primaryColor }}
                            >
                                Siste uke
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => setPeriod('1M')}
                                style={buttonStyles}
                                sx={{ mr: 1.5, color: period === '1M' ? primaryColor : selectedColor, backgroundColor: period === '1M' ? selectedColor : primaryColor }}
                            >
                                Denne måneden
                            </Button>
                            <Button
                                variant='contained'
                                onClick={() => setPeriod('YTD')}
                                style={buttonStyles}
                                sx={{ mr: 1.5, color: period === 'YTD' ? primaryColor : selectedColor, backgroundColor: period === 'YTD' ? selectedColor : primaryColor }}
                            >
                                Hittil i år
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className='kpi-dashboard-content' style={dashboardStyles} sx={{ p: '1rem 3rem 3rem 3rem', height: '96%' }}>
                        <Grid item xs={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <KPICard title={'Inntekter'}>
                                        <IncomeHalfDoughnutChart token={token} period={period} />
                                    </KPICard>
                                </Grid>
                                <Grid item xs={6}>
                                    <KPICard title={'Forvaltningskapital'}>
                                        <CapitalSeriesChart token={token} period={period} />
                                    </KPICard>
                                </Grid>
                                <Grid item xs={12}>
                                    <KPICard title={'Netto kapitaltilstrømning'}>
                                        <NetFlowSeriesChart token={token} period={period} />
                                    </KPICard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <KPICard title={'Aktive kunder'}>
                                        <ActiveCustomersSeriesChart token={token} period={period} />
                                    </KPICard>
                                </Grid>
                                <Grid item xs={12}>
                                    <KPICard title={'Utvikling for bank'}>
                                        <BankInfo token={token} period={period} />
                                    </KPICard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        </QueryClientProvider>
    )
}
