import { PURPLE, SPRING_GREEN } from '../../../../colors/colors'
import { useQuery } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'

import EChart from '../EChart'
import { Box, Typography } from '@mui/material'
import CurrencyOutput from '../../../fixrateCity/src/components/CurrencyOutput/CurrencyOutput'
import { Period } from '../../KPIDashboard'

type IncomeData = {
    WEEK: {
        value: number
        change: number
        budget: number
    }
    MONTH: {
        value: number
        change: number
        budget: number
    }
    YTD: {
        value: number
        budget: number
    }
}

export const IncomeHalfDoughnutChart = ({ token, period }: { token: string, period:Period }) => {
    const { isPending, error, data } = useQuery({
        queryKey: ['income', token],
        queryFn: () => GET('/api/stagg/kpi/income', token),
    })

    let incomeData: IncomeData
    let incomeValue: number = 0
    let budgetValue: number = 0
    let diffBudgetIncome: number = 0
    let percentOfBudget: string= '0'
    let incomeChange: number = 0

    const periodMapping: { [key in Period]: string } = {
        '1W': 'WEEK',
        '1M': 'MONTH',
        'YTD': 'YTD',
    }
    const periodString = periodMapping[period]

    // a mapping string for the period
    const periodText = {
        '1W': 'Siste uke',
        '1M': 'Siste måned',
        YTD: 'Hittil i år',
    }

    if (!isPending && data) {
        incomeData = data
        incomeValue = incomeData[periodString].value.toFixed(0)
        budgetValue = incomeData[periodString].budget.toFixed(0)
        incomeChange = incomeData[periodString].change ? incomeData[periodString].change.toFixed(0) : 0
        diffBudgetIncome = budgetValue - incomeValue
        
        percentOfBudget = ((incomeValue / budgetValue) * 100).toFixed(0) 
    }

    const incomeHalfDoughnutOptions = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: 'bottom',
            left: 'left',
            icon: 'circle',
            padding: 0,
        },
        series: [
            {
                name: 'Inntekter',
                type: 'pie',
                radius: ['40%', '80%'],
                center: ['50%', '80%'],
                startAngle: 180,
                endAngle: 360,
                data: [
                    { value: incomeValue, name: periodText[period], color: SPRING_GREEN[500] },
                    {
                        value: diffBudgetIncome,
                        name: 'Til oppnåelse av budsjett',
                        color: PURPLE[400],
                    },
                ],
            },
        ],
    }

    const incomeTrend = (value: number) => {
        if (value === 0) return ''
        return value > 0 ? `+ ${CurrencyOutput.formatMillionWithDecimals(value)}` : `${CurrencyOutput.formatMillionWithDecimals(value)}`   
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <Typography variant='h2' fontWeight={800} display={'inline-flex'}>
                {CurrencyOutput.formatMillionWithDecimals(incomeValue)}
                <Box sx={{ display: 'inline-flex' }}>
                    <Typography sx={{ display: 'flex', pl: '1rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }} variant='h5'>
                        {incomeTrend(incomeChange)}
                    </Typography>
                    <Typography variant='h2' sx={{ fontWeight: '600', pl: '3rem', color: SPRING_GREEN[500]}}>
                        {percentOfBudget}%
                    </Typography>
                    <Typography variant='h6' sx={{ fontWeight: '600', display: 'flex', pl: '1rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }}>
                        Av budsjett
                    </Typography>
                </Box>
            </Typography>

            <EChart loading={isPending} options={incomeHalfDoughnutOptions} error={error} style={{ height: '28vh' }} />
        </Box>
    )
}
