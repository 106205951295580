import { useQueries } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'
import { EChartsOption } from '../echarts'
import EChart from '../EChart'
import { PURPLE, SIGNAL_YELLOW, SPRING_GREEN } from '../../../../colors/colors'
import { Box, Typography } from '@mui/material'
import CurrencyOutput from '../../../fixrateCity/src/components/CurrencyOutput/CurrencyOutput'
import { Period } from '../../KPIDashboard'

export const CapitalSeriesChart = ({ token, period }: { token: string, period: Period }) => {

    const results = useQueries({
        queries: [
            {
                queryKey: ['capital-series' + period, token],
                queryFn: () => GET('/api/stagg/kpi/capital-series?range=' + period, token),
            },
            {
                queryKey: ['capital', token],
                queryFn: () => GET('/api/stagg/kpi/capital', token),
            },
        ],
    })

    
    let capitalSeries
    let capital
    let xAxisValues: string[]

    let deposits: number[]
    let funds: number[]
    let budget: number[]

    let capitalValue: number
    let capitalChange: number

    const periodMapping: { [key in Period]: string } = {
        '1W': 'WEEK',
        '1M': 'MONTH',
        YTD: 'YTD',
    }
    const periodString = periodMapping[period]
    
    if (!results[0].isPending && !results[1].isPending) {
        capitalSeries = results[0]
        capital = results[1].data
        const dataToMap = results[0].data[0].series
        const series = dataToMap.reduce(
            (result, data) => ({
                ...result,
                [data.name]: data.values,
            }),
            {}
        )
        xAxisValues = series.deposits.map(value => value[0])
        deposits = series.deposits.map(value => value[1].toFixed(0) ?? 0)
        funds = series.funds.map(value => value[1].toFixed(0) ?? 0)
        budget = series.budget.map(value => value[1].toFixed(0) ?? 0)

        capitalValue = capital['YESTERDAY'].value.toFixed(0)
        capitalChange = capital[periodString].change ? capital[periodString].change.toFixed(0) : 0


    }

    const CapitalSeriesOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: xAxisValues,
            },
        ],
        yAxis: [
            {
                type: 'value',
            },
        ],
        legend: {
            data: ['Bank volum', 'Fond volum', 'Budsjett (totalt)'],
            right: '5%',
            top: 0,
        },
        series: [
            {
                name: 'Budsjett (totalt)',
                type: 'line',
                smooth: true,
                color: SIGNAL_YELLOW[600],
                lineStyle: {
                    width: 2,
                    color: SIGNAL_YELLOW[600],
                },
                showSymbol: true,
                emphasis: {
                    focus: 'series',
                },
                data: budget,
            },
            {
                name: 'Bank volum',
                type: 'line',
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                color: PURPLE[300],
                showSymbol: false,
                areaStyle: {
                    color: PURPLE[300],
                },
                emphasis: {
                    focus: 'series',
                },
                data: deposits,
            },
            {
                name: 'Fond volum',
                type: 'line',
                stack: 'Total',
                smooth: true,
                color: SPRING_GREEN[500],
                lineStyle: {
                    width: 0,
                    color: SPRING_GREEN[500],
                },
                showSymbol: false,
                areaStyle: {
                    color: SPRING_GREEN[500],
                },
                emphasis: {
                    focus: 'series',
                },
                data: funds,
            },
        ],
    }

    const incomeTrend = (value: number) => {
        if (value === 0) return ''
        return value > 0 ? `+ ${CurrencyOutput.formatMillionWithDecimals(value)}` : `${CurrencyOutput.formatMillionWithDecimals(value)}`
    }

    return (
        <>
            <Typography variant='h2' fontWeight={800} display={'inline-flex'}>
                {CurrencyOutput.formatBillion(capitalValue*1000000)}
                <Box sx={{ display: 'inline-flex' }}>
                    <Typography sx={{ display: 'flex', pl: '1rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }} variant='h5'>
                        {incomeTrend(capitalChange)}
                    </Typography>
                </Box>
            </Typography>
            <EChart loading={capitalSeries?.isPending} options={CapitalSeriesOptions} error={capitalSeries?.error} style={{ height: '28vh' }} />
        </>
    )
            
}
