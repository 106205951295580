import { useQueries } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'
import { EChartsOption } from '../echarts'
import EChart from '../EChart'
import { PURPLE, SIGNAL_YELLOW, SPRING_GREEN } from '../../../../colors/colors'
import { Box, Grid, LinearProgress, Typography, linearProgressClasses, styled } from '@mui/material'
import { Period } from '../../KPIDashboard'

interface BorderLinearProgressProps {
    type: 'new'|'active'
}

export const ActiveCustomersSeriesChart = ({ token, period }: { token: string, period:Period }) => {
    const results = useQueries({
        queries: [
            {
                queryKey: ['customers-series' + period, token],
                queryFn: () => GET('/api/stagg/kpi/active-customers-series?range=' + period, token),
            },
            {
                queryKey: ['new-customers', token],
                queryFn: () => GET('/api/stagg/kpi/new-customers', token),
            },
            {
                queryKey: ['active-customers', token],
                queryFn: () => GET('/api/stagg/kpi/active-customers', token),
            },
        ],
    })

    let activeCustomersSeries
    let newCustomers
    let activeCustomers

    let xAxisValues: string[]

    let customers: number[]
    let target: number[]

    let newCustomersPercentage: number = 0
    let activeCustomersPercentage: number = 0

    let newCustomersValue: number
    let newCustomersBudget: number
    let activeCustomersValue: number
    let activeCustomersBudget: number

    const periodMapping: { [key in Period]: string } = {
        '1W': 'WEEK',
        '1M': 'MONTH',
        YTD: 'YTD',
    }
    const periodString = periodMapping[period]
        
    if (!results[0].isPending && !results[1].isPending && !results[2].isPending) {
        activeCustomersSeries = results[0]?.data
        newCustomers = results[1]?.data
        activeCustomers = results[2]?.data

        const dataToMap = activeCustomersSeries[0].series
        const series = dataToMap.reduce(
            (result, data) => ({
                ...result,
                [data.name]: data.values,
            }),
            {}
        )

        xAxisValues = series.activeCustomers.map(value => value[0])
        customers = series.activeCustomers.map(value => value[1])
        target = series.budget.map(value => value[1].toFixed(0))

        newCustomersValue = newCustomers[periodString].value
        newCustomersBudget = newCustomers[periodString].budget.toFixed(0)
        activeCustomersValue = activeCustomers['YESTERDAY'].value
        activeCustomersBudget = activeCustomers['YESTERDAY'].budget

        newCustomersPercentage = +((newCustomersValue / newCustomersBudget) * 100).toFixed(0)
        activeCustomersPercentage = +((activeCustomersValue / activeCustomersBudget) * 100).toFixed(0)

    }

    const CustomerSeriesOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            data: ['Aktive kunder', 'Mål'],
            right: '5%',
            top: 0,
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxisValues,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: 'Aktive kunder',
                type: 'line',
                smooth: true,
                symbol: 'triangle',
                symbolSize: 6,
                showSymbol: true,
                data: customers,
                color: PURPLE[300],
                lineStyle: {
                    width: 3,
                    type: 'solid',
                    color: PURPLE[300],
                },
            },
            {
                name: 'Mål',
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 6,
                showSymbol: true,
                data: target,
                color: SIGNAL_YELLOW[600],
                lineStyle: {
                    width: 3,
                    type: 'solid',
                    color: SIGNAL_YELLOW[600],
                },
            },
        ],
    }

    const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(({ type }) => ({
        height: '3rem',
        borderRadius: '2rem',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: PURPLE[800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: '2rem',
            backgroundColor: type === 'new' ? SPRING_GREEN[500] : PURPLE[300],
        },
    }))

    return (
        <Box style={{ height: '52vh' }}>
            <EChart loading={activeCustomersSeries?.isPending} options={CustomerSeriesOptions} error={activeCustomersSeries?.error} style={{ height: '35vh' }} />
            <Grid container spacing={2} sx={{ p: '2rem' }}>
                <Grid item xs={6}>
                    <Typography variant='h5' fontWeight={600}>
                        {newCustomersValue} Nye kunder
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h5' fontWeight={600} sx={{ textAlign: 'right' }}>
                        {`${newCustomersBudget - newCustomersValue} til så er målet nådd`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <BorderLinearProgress variant='determinate' value={newCustomersPercentage > 100 ? 100 : newCustomersPercentage} type='new' />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h5' fontWeight={600}>
                        {activeCustomersValue} Aktive kunder
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h5' fontWeight={600} sx={{ textAlign: 'right' }}>
                        {`${activeCustomersPercentage.toFixed(0)}% av mål`}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <BorderLinearProgress variant='determinate' value={activeCustomersPercentage > 100 ? 100 : activeCustomersPercentage} type='active' />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
