import { useQuery } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'

import { Box, Grid, Typography } from '@mui/material'
import { PURPLE, SIGNAL_YELLOW, SPRING_GREEN } from '../../../../colors/colors'
import CurrencyOutput from '../../../fixrateCity/src/components/CurrencyOutput/CurrencyOutput'
import { Period } from '../../KPIDashboard'

type Series ={ YESTERDAY: {
            value: number
        },
        WEEK: {
            change: number
        },
        MONTH: {
            change: number
        },
        YTD: {
            change: number
        }}

type Response = {
    totalDepth: Series,
    numAds: Series,
    numBanksWithDeposits: Series,
    numBanksWithAds: Series
}

export const BankInfo = ({ token, period }: { token: string, period: Period }) => {
    const { isPending, error, data } = useQuery({
        queryKey: ['bank-trends' + period, token],
        queryFn: () => GET('/api/stagg/kpi/banks', token),
    })

    const periodMapping: { [key in Period]: string } = {
        '1W': 'WEEK',
        '1M': 'MONTH',
        YTD: 'YTD',
    }
    const periodString = periodMapping[period]

    let totalDepth: number
    let totalDepthTrend: number
    let numAds: number
    let numAdsTrend: number
    let numBanksWithDepositsTrend: number
    let numBanksWithAdsTrend: number
    let numBanksWithDeposits: number
    let numBanksWithAds: number

    if (!isPending && data) {
        const response: Response = data
        totalDepth = response.totalDepth['YESTERDAY'].value * 1000000
        totalDepthTrend = data.totalDepth[periodString].change
        numAdsTrend = data.numAds[periodString].change
        numAds = data.numAds['YESTERDAY'].value

        numBanksWithAdsTrend = data.numBanksWithAds[periodString].change
        numBanksWithAds = data.numBanksWithAds['YESTERDAY'].value
        numBanksWithDepositsTrend = data.numBanksWithDeposits[periodString].change
        numBanksWithDeposits = data.numBanksWithDeposits['YESTERDAY'].value
    }

    const trend = (value: number) => {
        if (value === 0)
            return (
                <Typography sx={{ color: SIGNAL_YELLOW[600] }} variant='h3' display={'inline-flex'}>
                    <i className='ri-arrow-right-line'></i>
                </Typography>
            )
        return (
            <Typography sx={{ color: SPRING_GREEN[500] }} variant='h3' display={'inline-flex'}>
                {value > 0 ? <i className='ri-arrow-right-up-line'></i> : <i className='ri-arrow-right-down-line'></i>}
            </Typography>
        )
    }

    return (
        <Box style={{ height: '15vh' }}>
            <Typography variant='h2' sx={{ fontWeight: 800, display: 'inline-flex', pb: '2rem' }}>
                {CurrencyOutput.formatMillion(totalDepth)}
                <Box sx={{ display: 'inline-flex' }}>
                    <Typography sx={{ display: 'flex', pl: '1.5rem', pr:'1.5rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }} variant='h5'>
                        Annonsert volum
                    </Typography>
                </Box>
                {trend(totalDepthTrend)}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: PURPLE[700], p: '1rem', borderRadius: '1rem' }}>
                        <Typography variant='h3' fontWeight={600}>
                            {numAds}
                            {trend(numAdsTrend)}
                        </Typography>
                        <Typography variant='h6' sx={{ color: SPRING_GREEN[500] }}>
                            Antall annonser
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: PURPLE[700], p: '1rem', borderRadius: '1rem' }}>
                        <Typography variant='h3' fontWeight={600}>
                            {numBanksWithDeposits}
                            {trend(numBanksWithDepositsTrend)}
                        </Typography>
                        <Typography variant='h6' sx={{ color: SPRING_GREEN[500] }}>
                            Banker med innskudd
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ backgroundColor: PURPLE[700], p: '1rem', borderRadius: '1rem' }}>
                        <Typography variant='h3' fontWeight={600}>
                            {numBanksWithAds}
                            {trend(numBanksWithAdsTrend)}
                        </Typography>
                        <Typography variant='h6' sx={{ color: SPRING_GREEN[500] }}>
                            Banker med annonser
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
