import classNames from "classnames";
import { isToday, set } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { closeWebsocket } from "../../../websocketConnection";
import Dashboard from "../../Dashboard";
import styles from "./app.module.scss";
import BankRow from "./components/BankRow/BankRow";
import BankView from "./components/BankView/BankView";
import Communication from "./components/Communication/Communication";
import FridaySummary from "./components/FridaySummary/FridaySummary";
import {
    peopleImages
} from "./components/LoggedInPerson/LoggedInPerson";
import Vault from "./components/MoneyPool/Vault";
import { calendarEvents } from "./data/calendarEvents";
import fixrateHQ from "./images/Fixrate-HQ.svg";
import bench from "./images/bench.svg";
import chopper from "./images/chopper.svg";
import cinema from "./images/cinema.svg";
import fighterJet from "./images/fighter-jet.svg";
import hangGlider from "./images/hangglider.svg";
import light from "./images/light.svg";
import { ReactComponent as MoneyBag } from "./images/pathgroup.svg";
import plane from "./images/plane.svg";
import tesla from "./images/tesla.svg";
import tree from "./images/tree.svg";
import { WebsocketContext } from "./providers/WebsocketContext";
import chopperPassingBySoundFile from "./sounds/chopper-passing-by.wav";
import dangerZoneSoundFile from "./sounds/danger-zone.wav";
import planeSoundFile from "./sounds/plane-sound.mp3";
import coin from "./sounds/smw_coin.wav";
import topGunSoundFile from "./sounds/top-gun-short-2.mp3";
import { AdDto, BankDto, DepositBuyOrderType, DepositDto, FundBuyOrderType, User, UserLogOutEvent } from "./types";
import { LedLogo } from "./utils/ledwInterface";
import { GET } from "./utils/networkCallApi";


function createPlaneObject(orderVolume: number, orderCatgory: "DEPOSIT" | "FUND") {
    if (orderCatgory === "FUND") {
        if (orderVolume > 10) {
            return {
                sound: new Audio(dangerZoneSoundFile),
                image: chopper,
            }
        } else {
            return {
                sound: new Audio(chopperPassingBySoundFile),
                image: chopper,
            }
        }
    }
    if (orderVolume <= 5) {
        return {
            sound: null,
            image: hangGlider,
        };
    } else if (orderVolume <= 20) {
        return {
            sound: new Audio(planeSoundFile),
            image: plane,
        };
    } else {
        return {
            sound: new Audio(topGunSoundFile),
            image: fighterJet,
        };
    }
}

//const funds : FundData[] = require("./data/fundData.json")

export type OrderType = DepositBuyOrderType | FundBuyOrderType

function FixrateCity({ jwt = null, decodedJwt, dashboard }) {
    const [communicationVisible, setCommunicationVisible] = useState(false);
    const [communicationName, setCommunicationName] = useState("");
    const [communicationTitle, setCommunicationTitle] = useState("");
    const [communicationMessage, setCommunicationMessage] = useState("");
    const [communicationActions, setCommunicationActions] = useState([]);
    const [depositors, setDepositors] = useState([]);
    const [deposits, setDeposits] = useState<DepositDto[]>([]);
    const [banks, setBanks] = useState<BankDto[]>([]);
    const [ads, setAds] = useState<AdDto[]>([]);
    const [cityWidth, setCityWidth] = useState(1000);
    const [usersLoggedIn, setUsersLoggedIn] = useState<User[]>([]);

    const [smokeTestIsFailing, setSmokeTestIsFailing] = useState(false)
    const [nightTestIsFailing, setNightTestIsFailing] = useState(false)

    const websocketContext = useContext(WebsocketContext)

    const currentOffice = useParams().office

    const [newOrder, setNewOrder] = useState<OrderType | null>(null);
    const [newOrderAnimationQueue, setNewOrderAnimationQueue] = useState<OrderType[]>([]);
    const [newOrderAnimationPlaying, setNewOrderAnimationPlaying] =
        useState(false);

    const [currentDay, setCurrentDay] = useState(new Date().getDay());

    const [showFridaySummary, setShowFridaySummary] = useState(false);

    const [weatherCondition, setWeatherCondition] = useState("");
    const [temperature, setTemperature] = useState(0);
    const [sunIsUp, setSunIsUp] = useState(true);

    const [selectedBank, setSelectedBank] = useState<BankDto | null>(null);

    const [initialVolume, setInitialVolume] = useState(0)
    const [loadedVolume, setLoadedVolume] = useState(false)

    const coinSound = new Audio(coin);

    const ledLogo = new LedLogo();

    const msg = new SpeechSynthesisUtterance();

    const vaultRef = useRef<any>();

    const handleAddMoneyBag = () => {
        if (vaultRef.current) {
            vaultRef.current.addMoneyBag();
        }
    };

    const handleResetVault = () => {
        if (vaultRef.current) {
            vaultRef.current.resetVault();
        }
    }

    msg.lang = "en-US";
    msg.rate = 1;

    function getWeatherData() {
        fetch(
            "https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=63.446827&lon=10.421906"
        )
            .then((response) => response.json())
            .then((data) => {
                const now = new Date().getHours();
                const chosenData = data.properties.timeseries.find(
                    (series) => new Date(series.time).getHours() === now
                ).data;
                setWeatherCondition(
                    chosenData.next_1_hours.summary.symbol_code
                );
                setTemperature(chosenData.instant.details.air_temperature);
            });
    }

    const addUser = (payload: User) => {
        if (usersLoggedIn.find(u => u.id === payload.id)) {
            console.log("nah")
            return
        }
        const randomImageNumber = Math.floor(
            Math.random() * peopleImages.length
        );
        const loginArr = [...usersLoggedIn, { ...payload, imageNumber: randomImageNumber }]
        setUsersLoggedIn(loginArr)
    }

    const removeUser = (payload: UserLogOutEvent) => {
        const arr = [...usersLoggedIn].filter((user) => user.id !== payload.id)
        setUsersLoggedIn(arr)
    }

    useEffect(() => {
        if (websocketContext.state.order) {
            setNewOrderAnimationQueue([
                ...newOrderAnimationQueue,
                websocketContext.state.order,
            ])
            for (let i = 0; i < websocketContext.state.order.volume; i++) {
                handleAddMoneyBag()
            }
        }
    }, [websocketContext.state.order])

    useEffect(() => {
        if (websocketContext.state.nightTest !== null) {
            if (websocketContext.state.nightTest.success === false && !nightTestIsFailing) {
                setNightTestIsFailing(true)
                ledLogo.startAlarm();
                if (currentOffice.toUpperCase() === "TRONDHEIM") {
                    showCommunication(
                        "Dr. Evil",
                        "CEO of Virtucon",
                        ["Well, actually that was just footage from the movie Independence Day but the real laser would be a lot like that."],
                        [],
                        null
                    )
                }
            }
            if (websocketContext.state.nightTest.success === true) {
                setNightTestIsFailing(false)
            }
        }
    }, [websocketContext.state.nightTest])

    useEffect(() => {
        if (websocketContext.state.smokeTest !== null) {
            if (websocketContext.state.smokeTest.success === false && !smokeTestIsFailing) {
                setSmokeTestIsFailing(true)
                ledLogo.startAlarm();
                if (currentOffice.toUpperCase() === "TRONDHEIM") {
                    showCommunication(
                        "Dr. Evil",
                        "CEO of Virtucon",
                        ["You know, I have one simple request, and that is to have sharks with frickin' laser beams attached to their heads!"],
                        [],
                        null
                    )
                }
            }
            if (websocketContext.state.smokeTest.success === true) {
                setSmokeTestIsFailing(false)
            }
        }
    }, [websocketContext.state.smokeTest])

    useEffect(() => {
        if (vaultRef.current) {
            vaultRef.current.createWorld();
        }
    }, [vaultRef.current])

    useEffect(() => {
        GET("/api/stagg/depositors/table", jwt).then((response) => {
            setDepositors(response);
        });
        GET("/api/stagg/deposits/deposits-table", jwt).then((response) => {
            setDeposits(response);
        });
        GET("/api/stagg/ads/table", jwt).then((response) => {
            setAds(response);
        });
        GET("/api/stagg/orders/active", jwt).then((response) => {
            const ordersToday = response.filter((order) =>
                isToday(new Date(order.created))
            );
            const volumeToday = ordersToday.reduce(
                (partialSum, a) => partialSum + a.volume,
                0
            )
            setInitialVolume(volumeToday)
            setLoadedVolume(true)
        });
        GET("/api/stagg/banks/table", jwt).then((response) => {
            setBanks(response);
        })

        // Getting weather data once an hour
        getWeatherData();
        const weatherInterval = setInterval(getWeatherData, 3600_000);

        return () => {
            clearInterval(weatherInterval);
            closeWebsocket();
        };
    }, []);

    useEffect(() => {
        if (!newOrderAnimationPlaying && newOrderAnimationQueue.length !== 0) {
            setNewOrderAnimationPlaying(true);
            ledLogo.startCelebration();
            const queue = [...newOrderAnimationQueue];
            const order = queue.shift();
            setNewOrderAnimationQueue(queue);
            setNewOrder(order);
            setCommunicationVisible(false);
            coinSound?.play();
            const orderBuildingElement = order.category === "DEPOSIT" ? document.getElementById(order.bankId) : document.getElementById("fixrateStockExchange")
            orderBuildingElement.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "center",
            })
            
            setTimeout(() => {
                const flyingObj = createPlaneObject(order.volume, order.category);
                flyingObj.sound?.play();
            }, 3_000);

            setTimeout(() => {
                const fixrateAreaElement = document.getElementById("fixrateArea");
                fixrateAreaElement.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "start",
                });
                setNewOrderAnimationPlaying(false);
            }, 30_000)
        }
    }, [newOrderAnimationQueue, newOrderAnimationPlaying]);

    useEffect(() => {
        setCityWidth(
            document.getElementById("fixrateArea").clientWidth +
            document.getElementById("banks").clientWidth +
            document.getElementById("fixrateStockExchange").clientWidth +
            500
        );
    }, [banks, depositors]);

    const speakFn = (text: string) => {
        setTimeout(() => {
            msg.text = text;
            window.speechSynthesis.speak(msg);
        }, 5000);
    };

    const showCommunication = (name: string, title: string, message: string[], actions, speak: string) => {
        setCommunicationVisible(false);
        setCommunicationName(name);
        setCommunicationTitle(title);
        setCommunicationMessage(
            message[Math.floor(Math.random() * message.length)]
        );
        setCommunicationVisible(true);
        setCommunicationActions(actions);
        if (speak) {
            setTimeout(() => {
                speakFn(speak);
            }, 5000);
        }
    };

    const exitSummary = () => {
        setShowFridaySummary(false);
    };

    useEffect(() => {
        const calendarInterval = setInterval(() => {
            const now = new Date();
            const day = now.getDay();
            const hour = now.getHours();
            const minutes = now.getMinutes();
            const matchingEvent = calendarEvents.filter(event => event.office === currentOffice.toUpperCase() || event.office === "ALL").find(
                (cEvent) =>
                    cEvent.days.indexOf(day) !== -1 &&
                    cEvent.hour === hour &&
                    cEvent.minutes === minutes
            );

            // Check for friday summary
            if (day === 5 && hour === 12 && minutes === 0) {
                setShowFridaySummary(true);
            }
            // New day, new vault
            if (day !== currentDay) {
                handleResetVault()
                setCurrentDay(day)
            }

            if (matchingEvent) {
                showCommunication(
                    matchingEvent.person.name,
                    matchingEvent.person.title,
                    matchingEvent.message,
                    matchingEvent.actions,
                    matchingEvent.speak
                );
            }
        }, 60000);
        return () => clearInterval(calendarInterval);
    }, []);

    return (
        <div
            className={classNames(styles.city, !sunIsUp && styles.night)}
            style={{ width: cityWidth }}
        >
            {weatherCondition.includes("rain") && (
                <div className={classNames(styles.weather, styles.gloomy)}>
                    {[...new Array(10)].map((_, index) => (
                        <div
                            key={index}
                            className={classNames(
                                styles.rain,
                                weatherCondition === "lightrain"
                                    ? styles.light
                                    : null
                            )}
                        >
                            {[...new Array(4)].map((_, index) => (
                                <div key={index}>
                                    <div className={styles.front}></div>
                                    <div className={styles.back}></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
            <header>
                <div>
                    <img
                        alt=""
                        className={styles.logo}
                        src={
                            sunIsUp
                                ? "/fixrate-city-logo.svg"
                                : "/fixrate-city-logo-inverted.svg"
                        }
                        onClick={() => ledLogo.startAlarm()}
                    />
                </div>
                <div className={styles.chipContainer}>
                    {websocketContext?.state?.nightTest !== null && (
                        <span className={classNames(styles.chip)}>
                            <span
                                className={classNames(
                                    styles.circle,
                                    websocketContext?.state?.nightTest.success
                                        ? styles.success
                                        : styles.error
                                )}
                            />
                            <span className={styles.text}>
                                Natt-testen {websocketContext?.state?.nightTest.message}
                            </span>
                        </span>
                    )}
                    {websocketContext?.state?.smokeTest !== null && (
                        <span className={classNames(styles.chip)}>
                            <span
                                className={classNames(
                                    styles.circle,
                                    websocketContext?.state?.smokeTest.success
                                        ? styles.success
                                        : styles.error
                                )}
                            />
                            <span className={styles.text}>
                                Smoke {websocketContext?.state?.smokeTest.message}
                            </span>
                        </span>
                    )}
                </div>
            </header>
            {newOrderAnimationPlaying && newOrder && (
                <div className={styles.planeLineWrapper}>
                    <div
                        className={classNames(
                            styles.planeWithBanner,
                            styles.flyOver
                        )}
                    >
                        <div className={styles.banner}>
                            <div>
                                <h2>Nytt innskudd!</h2>
                                <p>
                                    {newOrder.volume} millioner plassert av{" "}
                                    {newOrder.depositorName}
                                </p>
                            </div>
                        </div>
                        <div className={styles.line}></div>
                        <img
                            src={createPlaneObject(newOrder.volume, newOrder.category).image}
                            alt=""
                        />
                    </div>
                    <div className={styles.moneyDrop}>
                        {newOrder !== undefined
                            ? //@ts-ignore
                              [...new Array(newOrder.volume)].map(
                                  (_, index) => (
                                      <div
                                          key={index}
                                          style={{
                                              animationDelay:
                                                  10 +
                                                  index /
                                                      (newOrder.volume / 2) +
                                                  "s",
                                              marginLeft: index * 10 + "px",
                                          }}
                                      />
                                  )
                              )
                            : null}
                    </div>
                </div>
            )}
            <div
                className={classNames(
                    styles.street,
                    weatherCondition.includes("cloudy") ? styles.cloudy : null
                )}
            >
                <div id="fixrateArea" className={styles.fixrateArea}>
                    <div className={styles.cinema}>
                        <div className={styles.dashboardContainer}>
                            {!communicationVisible &&
                                !newOrderAnimationPlaying && (
                                    <Dashboard
                                        jwt={jwt}
                                        dashboard={dashboard}
                                        decodedJwt={decodedJwt}
                                    />
                                )}
                        </div>
                        <img className={styles.screen} src={cinema} alt={""} />
                        <img className={styles.car} src={tesla} alt={""} onClick={() => setShowFridaySummary(true)}/>
                        {/*<div className={styles.people}>
                            {usersLoggedIn.map((user) => (
                                <LoggedInPerson user={user} key={user.id} />
                            ))}
                        </div>*/}
                    </div>
                    <img alt="" className={styles.light} src={light} />
                    <img alt="" className={styles.tree} src={tree} />
                    <div className={styles.headQuarterWrapper}>
                        <img
                            alt=""
                            className={styles.headQuarter}
                            src={fixrateHQ}
                        />
                    </div>
                    { loadedVolume && <Vault ref={vaultRef} initialVolume={initialVolume} /> }
                    <img alt="" className={styles.tree} src={tree} />
                    <img alt="" className={styles.bench} src={bench} />
                    <img alt="" className={styles.tree} src={tree} />
                    <img alt="" className={styles.light} src={light} />
                </div>
                <BankRow banks={banks} onClick={setSelectedBank} />
                <div id="fixrateStockExchange" className={styles.stockExchange}>
                    <img
                        alt=""
                        src={"/buildings/stock-exchange/stock-exchange.svg"}
                    />
                </div>
                <div className={styles.hide}>
                    <MoneyBag />
                </div>
            </div>

            {showFridaySummary && (
                <FridaySummary jwt={jwt} exitFn={exitSummary} />
            )}

            {communicationVisible && (
                <Communication
                    name={communicationName}
                    title={communicationTitle}
                    message={communicationMessage}
                    actions={communicationActions}
                    close={() => setCommunicationVisible(false)}
                />
            )}
            {selectedBank && (
                <BankView
                    ads={ads.filter(a => a.bankId === selectedBank.id && a.public && a.deactivatedBy === null && a.remainingVolume > a.min)}
                    bank={selectedBank}
                    deposits={deposits.filter(d => d.bankId === selectedBank.id)}
                    onClose={() => setSelectedBank(null)}
                />
            )}
        </div>
    );
}

export default FixrateCity;
