import { AppBar } from '@mui/material'
import { FixrateLogoInverted } from '../../FixrateLogoInverted'
import { PURPLE } from '../../../../colors/colors'

const appBarStyles = {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    backgroundColor: PURPLE[300],
    gap: '1.5rem',
    color: 'white',
    padding: '0 3rem 1.5rem 3rem',
    boxShadow: 'none',
    '& p': {
        margin: 0,
        fontSize: '2rem',
    },
    '> svg': {
        width: '18rem',
        padding: '3.6rem 0 0 0',
    },
}

export const Header = () => {
    return (
        <AppBar position='static' sx={appBarStyles}>
            <FixrateLogoInverted />
            <p>Fixrate KPI Dashboard</p>
        </AppBar>
    )
}
