import { useQueries } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'
import { EChartsOption } from '../echarts'
import EChart from '../EChart'
import { Typography } from '@mui/material'
import { PURPLE, SPRING_GREEN } from '../../../../colors/colors'
import { Period } from '../../KPIDashboard'
import CurrencyOutput from '../../../fixrateCity/src/components/CurrencyOutput/CurrencyOutput'

export const NetFlowSeriesChart = ({ token, period }: { token: string, period:Period }) => {
        const results = useQueries({
        queries: [
            {
                queryKey: ['netflow-series'+period, token],
                queryFn: () => GET('/api/stagg/kpi/net-flow-series?range='+period, token),
            },
            {
                queryKey: ['netflow', token],
                queryFn: () => GET('/api/stagg/kpi/net-flow', token),
            },
        ],
    })

    let netflowSeries 
    let netflow 

    let xAxisValues: string[]

    /* let bankVolumeIn: number[]
    let bankVolumeOut: number[]
    let fundVolumeIn: number[]
    let fundVolumeOut: number[] */
    let budget: number[]
    let bankVolumeNet: number[]
    let fundVolumeNet: number[]

    let netFlowValue: number
    let netFlowChange: number

    const periodMapping: { [key in Period]: string } = {
        '1W': 'WEEK',
        '1M': 'MONTH',
        YTD: 'YTD',
    }
    const periodString = periodMapping[period]

    if (!results[0].isPending && !results[1].isPending) {
        netflowSeries = results[0]
        netflow = results[1].data
        const dataToMap = results[0].data[0].series
        const series = dataToMap.reduce(
            (result, data) => ({
                ...result,
                [data.name]: data.values,
            }),
            {}
        )
        xAxisValues = series.bankVolumeIn.map(value => value[0])
        bankVolumeNet = series.bankVolumeIn.map((value, index) => {
            let inValue = parseFloat(value[1].toFixed(0))
            let outValue = parseFloat(series.bankVolumeOut[index][1].toFixed(0))
            return inValue - outValue
        })
        fundVolumeNet = series.fundVolumeIn.map((value, index) => {
            let inValue = parseFloat(value[1].toFixed(0))
            let outValue = parseFloat(series.fundVolumeOut[index][1].toFixed(0))
            return inValue - outValue
        })

        /* bankVolumeIn = series.bankVolumeIn.map(value => value[1].toFixed(0))
        bankVolumeOut = series.bankVolumeOut.map(value => value[1].toFixed(0) * -1)
        fundVolumeIn = series.fundVolumeIn.map(value => value[1].toFixed(0))
        fundVolumeOut = series.fundVolumeOut.map(value => value[1].toFixed(0) * -1) */
        budget = series.budget.map(value => value[1].toFixed(0))

        netFlowValue = netflow[periodString].value.toFixed(0) * 1000000
        netFlowChange = netflow[periodString].change ? netflow[periodString].change.toFixed(0)*1000000 : 0
    }

    const netFlowSeriesOptions: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        toolbox: {
            feature: {
                saveAsImage: { show: true },
            },
        },
        legend: {
            data: ['Bank netto', 'Fond netto', 'Budsjett (totalt)'],
            left: 0,
            bottom: 0,
            icon: 'circle',
        },
        xAxis: {
            data: xAxisValues,
            name: 'Dato',
            axisLine: { onZero: true },
            splitLine: { show: false },
            splitArea: { show: false },
        },
        yAxis: {},
        grid: {
            bottom: 100,
        },
        series: [
            {
                name: 'Bank netto',
                type: 'bar',
                stack: 'bankNet',
                data: bankVolumeNet,
                color: PURPLE[300],
            },
            {
                name: 'Fond netto',
                type: 'bar',
                stack: 'fundNet',
                data: fundVolumeNet,
                color: SPRING_GREEN[500],
            },
            /* {
                name: 'Bank inn',
                type: 'bar',
                stack: 'bankVolume',
                data: bankVolumeIn,
                color: PURPLE[300],
            },
            {
                name: 'Bank ut',
                type: 'bar',
                stack: 'bankVolume',
                data: bankVolumeOut,
                color: PURPLE[300],
            },
            {
                name: 'Fond inn',
                type: 'bar',
                stack: 'fundVolume',
                data: fundVolumeIn,
                color: SPRING_GREEN[500],
            },
            {
                name: 'Fond ut',
                type: 'bar',
                stack: 'fundVolume',
                data: fundVolumeOut,
                color: SPRING_GREEN[500],
            }, */
            {
                name: 'Budsjett (totalt)',
                type: 'line',
                smooth: true,
                lineStyle: {
                    width: 2,
                    color: PURPLE[50],
                },
                showSymbol: true,
                data: budget,
                color: PURPLE[50],
            },
        ],
    }

    const incomeTrend = (value: number) => {
        if (value === 0) return ''
        return value > 0 ? `+ ${CurrencyOutput.formatMillionWithDecimals(value)}` : `${CurrencyOutput.formatMillionWithDecimals(value)}`
    }

    return (
        <>
            <Typography variant='h2' fontWeight={800} display={'inline-flex'}>
                {CurrencyOutput.formatMillionWithDecimals(netFlowValue)}
            </Typography>
            <Typography sx={{ display: 'inline-flex', pl: '1rem' }} variant='h5'>
                (Millioner i kapital til plattformen)
            </Typography>
            <Typography variant='h5' sx={{ color: SPRING_GREEN[400] }} fontWeight={600} pb={'1rem'}>
                {incomeTrend(netFlowChange)}
            </Typography>
            <EChart loading={netflowSeries?.isPending} options={netFlowSeriesOptions} error={netflowSeries?.error} style={{ height: '30vh' }} />
        </>
    )
    }
