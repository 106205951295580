import { createTheme } from '@mui/material'
import { PaletteColorOptions, PaletteOptions } from '@mui/material/styles/createPalette'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { FOREST_GREEN, PURPLE, SILVER_GRAY, SPRING_GREEN } from '../colors/colors'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        categoryTitle: React.CSSProperties
        ingress: React.CSSProperties
        label: React.CSSProperties
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        categoryTitle: true
        ingress: true
        label: true
    }
}

interface ExtendedTypographyOptions extends TypographyOptions {
    categoryTitle: React.CSSProperties
    ingress: React.CSSProperties
    label: React.CSSProperties
}

declare module '@mui/material/IconButton' {
    interface ButtonPropsColorOverrides {
        springGreen: true
        forestGreen: true
    }
    interface IconButtonPropsColorOverrides {
        springGreen: true
        forestGreen: true
    }
}

interface ExtendedPaletteOptions extends PaletteOptions {
    springGreen?: PaletteColorOptions
    forestGreen?: PaletteColorOptions
}

const TEXT_COLOR = PURPLE[900]

const theme = createTheme({
    palette: {
        primary: {
            main: PURPLE[500],
        },
        success: {
            main: PURPLE[500],
        },
        springGreen: {
            main: SPRING_GREEN[500],
        },
        forestGreen: {
            main: FOREST_GREEN[500],
        },
    } as ExtendedPaletteOptions,
    typography: {
        fontSize: 16,
        fontFamily: [
            '"Montserrat"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontWeightMedium: '600',
        fontWeightRegular: '500',
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 18,
            lineHeight: 1.55,
        },
        subtitle1: {
            fontSize: '1.2rem',
            color: SILVER_GRAY[500],
        },
        h4: {
            fontWeight: '700',
            marginBottom: '0',
            color: TEXT_COLOR,
            fontSize: '2.2rem',
            '@media (max-width: 1000px)': {
                fontSize: '1.8rem',
            },
        },
        h3: {
            fontWeight: '700',
            marginBottom: '0',
            color: TEXT_COLOR,
            fontSize: '3rem',
            '@media (max-width: 1000px)': {
                fontSize: '2.6rem',
            },
            '@media (max-width: 600px)': {
                fontSize: '2.2rem',
            },
        },
        h2: {
            fontWeight: '700',
            marginBottom: '3rem',
            color: TEXT_COLOR,
            fontSize: '5rem',
            lineHeight: '1.15em',
            '@media (max-width: 1000px)': {
                fontSize: '3rem',
                marginBottom: '0',
            },
        },
        h1: {
            fontWeight: '800',
            marginBottom: '4rem',
            marginTop: '2rem',
            color: TEXT_COLOR,
            '@media (max-width: 1000px)': {
                fontSize: '3.8rem',
                marginBottom: '2rem',
                marginTop: '1rem',
            },
        },
        categoryTitle: {
            color: PURPLE[500],
            fontSize: '2rem',
            fontWeight: '600',
            lineHeight: '1.15em',
            '@media (max-width: 1000px)': {
                fontSize: '2rem',
            },
        },
        ingress: {
            fontWeight: 400,
            fontFamily: '"Montserrat"',
            fontSize: '2.6rem',
            marginBottom: '2rem',
            maxWidth: '60rem',
            '@media (max-width: 1000px)': {
                fontSize: '1.8rem',
            },
        },
        label: {
            fontWeight: 700,
            fontSize: '2rem',
            fontFamily: '"Montserrat"',
        },
    } as ExtendedTypographyOptions,
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    categoryTitle: 'h2',
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        fontSize: '1.4rem',
                        margin: '0',
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                markLabel: {
                    fontSize: '1.6rem',
                    fontFamily: '"Montserrat"',
                    marginTop: '0.5rem',
                    fontWeight: '600',
                },
                thumb: {
                    fontSize: '1.4rem',
                    fontFamily: '"Montserrat"',
                    width: '2.5rem',
                    height: '2.5rem',
                },
                root: {
                    fontSize: '1.4rem',
                    fontFamily: '"Montserrat"',
                    width: '40rem',
                    height: '0.8rem',
                    maxWidth: '90%',
                },
                valueLabel: {
                    fontWeight: '600',
                    backgroundColor: PURPLE[50],
                    color: PURPLE[900],
                    padding: '0.6rem 2rem',
                    fontSize: '1.4rem',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    aspectRatio: '1',
                    '& i': {
                        fontSize: '3rem',
                    },
                    border: '0.2rem solid',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    textTransform: 'none',
                    fontSize: '2rem',
                    marginBottom: '2rem',
                    minWidth: '26rem',
                    '@media (max-width: 600px)': {
                        fontSize: '1.8rem',
                        width: '100%',
                    },
                },
                startIcon: {
                    lineHeight: '0',
                    marginRight: 0.5,
                },
                contained: {
                    boxShadow: '0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.4)',
                    height: '5.8rem',
                    ':disabled': {
                        color: '#6F7271',
                        backgroundColor: '#6F727120',
                    },
                },
                containedSizeSmall: {
                    height: '4.2rem',
                    fontSize: '1.6rem',
                    paddingLeft: '1.6rem',
                    paddingRight: '1.6rem',
                },
                outlinedSizeSmall: {
                    height: '4.2rem',
                    fontSize: '1.6rem',
                    paddingRight: '1.6rem',
                    paddingLeft: '1.6rem',
                },
                outlined: {
                    borderWidth: '0.2rem',
                    ':hover': {
                        borderWidth: '0.2rem',
                    },
                },
                sizeSmall: {
                    paddingTop: '0.4rem',
                    paddingBottom: '0.4rem',
                    minWidth: 'auto',
                },
                text: {
                    color: '#4D2A98',
                    textDecoration: 'underline',
                    textDecorationThickness: '0.1rem',
                    '&:hover': {
                        textDecoration: 'underline',
                        textDecorationThickness: '0.1rem',
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                    fontWeight: '700',
                    paddingLeft: '3rem',
                    paddingRight: '3rem',
                    position: 'relative',
                    zIndex: 1,
                    '& .MuiIconButton-root i': {
                        paddingRight: '0',
                        fontSize: '2rem',
                        margin: 0,
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthXl: {
                    margin: '20px',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                    fontWeight: '500',
                    '& .MuiAlert-icon': {
                        fontSize: '2.4rem',
                        marginRight: '1rem',
                    },
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                    fontWeight: '600',
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&.Mui-expanded': {
                        margin: '0.1rem 0',
                    },
                    '&.Mui-expanded:before': {
                        opacity: 1,
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: '6rem',
                    '& i': {
                        fontSize: '2rem',
                        color: PURPLE[500],
                    },
                    color: PURPLE[500],
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid rgba(0,0,0,0.1)',
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '1px 1px 4px 0px rgba(0,0,0,0.13)',
                },
            },
        },
    },
})

export default theme
